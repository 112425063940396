body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Galano Grotesque';
  background-color: #202122;
}

@font-face {
  font-family: 'Galano Grotesque';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Rene\ Bieder\ -\ Galano\ Grotesque\ SemiBold.otf')
    format('opentype');
}

a {
  outline: 0;
}

a:hover {
  text-decoration: none;
}

hr {
  border: 0;
  clear: both;
  display: block;
  background-color: #e3e3e3;
  height: 1px;
}

@media screen and (min-width: 480px) {
  body {
    min-width: 800px;
  }
  .App {
    position: absolute;
    height: 100%;
    width: 100%;
    /* background: #2fc3ff; */
    background: -webkit-linear-gradient(right, #2fc3ff, #f75aa2);
  }
}

/*phones*/
@media screen and (max-width: 480px) {
  body {
    min-width: 10;
  }
  .App {
    background-image: linear-gradient(76deg, #f75aa2 19%, #2fc3ff 85%);
  }
}

.navbar-tab {
  opacity: 0.9;
  -webkit-transition: all 100ms ease;
  -moz-transition: all 100ms ease;
  -o-transition: all 100ms ease;
  transition: all 100ms ease;
}

.navbar-tab:hover {
  opacity: 1;
}

.restaurant-logo {
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.restaurant-logo:hover {
  box-shadow: 2px 2px 25px 4px rgba(0, 0, 0, 0.12) !important;
}

.underline-hover {
  /* text-decoration: none; */
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.underline-hover:hover {
  text-decoration: underline;
}

.hover-opacity {
  opacity: 1;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.hover-opacity:hover {
  opacity: 0.9;
}

.activeLink {
  text-decoration: underline !important;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.home-link:hover {
  opacity: 0.8;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
